import Swiper, { Navigation, Pagination } from 'swiper';

// add animated hover
const addAnimatedHover = (item) => {
    if (item && window.innerWidth >= 1024) {
        // element for hover in mobile
        const hoverElement = document.createElement('span');
        hoverElement.classList.add('animated-hover');
        item.appendChild(hoverElement);

        item.addEventListener('mouseover', () => {
            const width = item.offsetWidth;
            hoverElement.style.width = `${width + 20}px`;
            hoverElement.style.height = `${width + 20}px`;
        });

        item.addEventListener('mouseout', () => {
            hoverElement.style.width = '0px';
            hoverElement.style.height = '0px';
        })
    }
};

// change title content in FAQ section
const addFaqTitle = (title) => {
    const [firstSpan, secondSpan] = document.getElementsByClassName('faq__sidebar-button-text');
    const [firstParagraph, secondParagraph] = document.getElementsByClassName('faq__title');

    [...[firstSpan, secondSpan], ...[firstParagraph, secondParagraph]].forEach((item) => {
        if (item) {
            item.textContent = title;
        }
    });
};

// add listeners to button into faq section
const faqButtons = [...document.getElementsByClassName('faq__sidebar-button')];
faqButtons.length && faqButtons.forEach((item) => {
    item.addEventListener('click', () => {
        if (item.classList.contains('is-active')) {
            item.classList.remove('is-active');
            activateMobileMenu(false);
        } else {
            item.classList.add('is-active');
            activateMobileMenu(true);
        }
    });
    // document.querySelectorAll('.faq__question-button').forEach(e => {
    //     if(!e.parentNode.classList.contains('is-disable') && e.classList.contains('is-active')) {
    //         let titleHeight = e.clientHeight;
    //         let descHeight = e.parentNode.querySelector('.faq__question-description').clientHeight;
    //         e.parentNode.style.height = titleHeight + descHeight + 'px'
    //     } 
    // })

    addAnimatedHover(item);
});

const activateMobileMenu = (activated) => {
    listElements.length && listElements.forEach((item, index) => {
        if (activated) {
            item.classList.add('is-active');
        } else {
            item.classList.remove('is-active');
        }
    });
};

// add listeners to switcher's buttons
const [leftContainer, rightContainer] = document.getElementsByClassName('faq__container');
const [leftButton, rightButton] = document.getElementsByClassName('hero__button');
const slider = document.getElementsByClassName('hero__slider')[0];
[leftButton, rightButton].forEach((item, index) => {
    item ? item.onclick = () => {
        if (!index) {
            rightButton.classList.remove('is-active');
            slider.classList.remove("hero__slider-right");
            leftContainer.style.display = 'flex';
            rightContainer.style.display = 'none';

            leftContainer.querySelector('.faq__list-button').click();
        } else {
            leftButton.classList.remove('is-active');
            slider.classList.add("hero__slider-right");
            leftContainer.style.display = 'none';
            rightContainer.style.display = 'flex';

            rightContainer.querySelector('.faq__list-button').click();
        }

        item.classList.add('is-active');
    }
        : null;
});


const [faqBlock] = document.getElementsByClassName('faq');
const listElements = [...document.getElementsByClassName('faq__list')];

// if page has switcher we need use not simple faq section
const isSwitcherUsed = !!document.getElementsByClassName('hero__switcher').length;
if (!isSwitcherUsed && faqBlock) {
    faqBlock.classList.add('faq--simple');
}

const options = [...document.getElementsByClassName('faq__list-button')];
options.forEach((item) => {
    item.addEventListener('click', () => {
        addActiveClass(item.dataset.id);
        showQuestions(item.dataset.id);
        addFaqTitle(item.textContent);
        addAnimatedHover(item);

        const src = item.querySelector('.faq__list-icon').getAttribute('src');
        faqButtons.length && faqButtons.forEach((item) => {
            if (item.classList.contains('is-active')) {
                item.classList.remove('is-active');

                item.querySelector('.icon').setAttribute('src', src);
            }
        });
        document.querySelectorAll('.faq__question-button').forEach(e => {
            if(!e.parentNode.classList.contains('is-disable') && e.classList.contains('is-active')) {
                let titleHeight = e.clientHeight;
                let descHeight = e.parentNode.querySelector('.faq__question-description').clientHeight;
                e.parentNode.style.height = titleHeight + descHeight + 'px'
            } else if (!e.parentNode.classList.contains('is-disable') && !e.classList.contains('is-active')) {
                let titleHeight = e.clientHeight;
                e.parentNode.style.height = titleHeight + 'px'
            } 
        })
        activateMobileMenu(false);
    });
});

// open question for this option
const showQuestions = (id) => {
    const questions = [...document.getElementsByClassName('faq__question')];

    questions.forEach((item) => {
        item.classList.add('is-disable');

        if (item.dataset.parentId === id) {
            item.classList.remove('is-disable');
        }
    });
};

// open question
const questionTitles = [...document.getElementsByClassName('faq__question-button')];
questionTitles.forEach((item) => {
    addAnimatedHover(item);
    const titleHeight = item.clientHeight;
    item.parentElement.style.height = `${titleHeight}px`;
    item.addEventListener('click', () => {
        if (item.classList.contains('is-active')) {
            item.classList.remove('is-active');
            item.querySelector('.animated-hover').style.width = 0;
            item.querySelector('.animated-hover').style.height = 0;
            item.parentElement.style.height = `${titleHeight}px`;
        } else {
            item.classList.add('is-active');
            item.querySelector('.animated-hover').style.width = item.offsetWidth + 100 + 'px';
            item.querySelector('.animated-hover').style.height = item.offsetWidth + 100 + 'px';
            const descriptionHeight = item.parentElement.querySelector('.faq__question-description').clientHeight;
            item.parentElement.style.height = `${titleHeight + descriptionHeight}px`;
        }
    });
});

window.onresize = () => {
    let w = document.querySelector('.container').offsetWidth;

    document.querySelectorAll('.faq__question-button').forEach(e => {
        if(!e.parentNode.classList.contains('is-disable') && e.classList.contains('is-active')) {
            let titleHeight = e.clientHeight;
            let descHeight = e.parentNode.querySelector('.faq__question-description').clientHeight;
            e.parentNode.style.height = titleHeight + descHeight + 'px'
        } else if (!e.parentNode.classList.contains('is-disable') && !e.classList.contains('is-active')) {
            let titleHeight = e.clientHeight;
            e.parentNode.style.height = titleHeight + 'px'
        } 
      
        if (e.classList.contains('is-active')) {
            e.querySelector('.animated-hover').style.width = w + 100 + 'px';
            e.querySelector('.animated-hover').style.height = w + 100 + 'px';
        } 
    });
}

// add ability for title in FAQ section be toggled
const addActiveClass = (id) => {
    const buttons = [...document.getElementsByClassName('faq__list-button')];

    buttons.forEach((item) => {
        item.classList.remove('is-active');

        if (item.dataset.id === id) {
            item.classList.add('is-active');
        }
    });
};

if (options.length) {
    options[0].click();
};

// hide teachers container
const teacherContainer = document.querySelector('.faq__container--teachers');
if (teacherContainer) {
    teacherContainer.style.display = 'none';
}

// slider init
const swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    loop: true,
    speed: 400,
    spaceBetween: 20,
    slidesPerView: 2,

    breakpoints: {
        575: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 30
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 30
        }
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    modules: [Navigation, Pagination],
});

// init of animated fade up text and blocks
AOS.init();
